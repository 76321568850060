import { BOOKED } from "../../models/BookingConstants.js";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";
import calculateStats from "./calculateStats.js";

function convertFlightToRoute({ route, nextState }) {
  route.count = nextState.sequence ? 1 : route.segments.length;
  route.startStation = nextState.stations.find((item) => item.id === (route.start || route.origin.id));
  route.endStation = nextState.stations.find((item) => item.id === (route.end || route.destination.id));

  if (nextState.sequence) {
    // Allow the route to have a title.
    route.routeName = getSegmentFullTitle({ segment: route, showFlightNumber: true });
  }

  route.hasLocation =
    route.startStation &&
    route.endStation &&
    route.startStation.lat !== undefined &&
    route.startStation.lon !== undefined &&
    route.endStation.lat !== undefined &&
    route.endStation.lon !== undefined;

  return route;
}

export default function GlobeDataReducer(state, action) {
  const { type, value } = action;
  if (type === "SET_STATE") {
    return { ...state, ...value };
  }

  let nextState;
  if (type === "SET_FLIGHTS") {
    nextState = { ...state, flights: value };
  } else if (type === "SET_STATIONS") {
    nextState = { ...state, stations: value };
  }

  if (nextState) {
    nextState.isLoaded = nextState.flights !== undefined && nextState.stations !== undefined;

    if (!nextState.isLoaded) {
      return nextState;
    }

    if (nextState.sequence) {
      // Data is loaded, add each specific flight as a route, even if it's an overlapping duplicate
      nextState.uniqueRoutes = nextState.flights;

      nextState.uniqueRoutes = [...nextState.flights].map((item) => {
        return convertFlightToRoute({ route: item, nextState });
      });
    } else {
      // Data is loaded, and flatten all the flights into non-duplicate routes
      nextState.uniqueRoutes = nextState.flights.reduce((acc, segment) => {
        // Don't show skipped or cancelled bookings on the map
        if (segment.isSkipped || segment.booking.status !== BOOKED || segment.isSecondaryPassenger) {
          return acc;
        }
        const routeParts = [segment.origin.id, segment.destination.id].sort();
        const routeKey = routeParts.join("-");
        const [start, end] = routeParts;
        if (!acc.has(routeKey)) {
          // add route
          acc.set(routeKey, { segments: [segment], start, end });
        } else {
          // modify
          const existingValue = acc.get(routeKey);
          acc.set(routeKey, { ...existingValue, segments: [existingValue.segments, segment] });
        }
        return acc;
      }, new Map());

      nextState.uniqueRoutes = [...nextState.uniqueRoutes].map((item) => {
        const route = item[1];
        return convertFlightToRoute({ route, nextState });
      });
    }

    nextState.uniqueRoutes = nextState.uniqueRoutes.filter((item) => item.hasLocation);

    const uniqueAirports = nextState.uniqueRoutes.reduce((acc, segment) => {
      if (!acc.has(segment.startStation.id)) {
        acc.set(segment.startStation.id, {
          id: segment.startStation.id,
          title: segment.startStation.title,
          lat: segment.startStation.lat,
          lon: segment.startStation.lon,
          country: segment.startStation.country,
        });
      }

      if (!acc.has(segment.endStation.id)) {
        acc.set(segment.endStation.id, {
          id: segment.endStation.id,
          title: segment.endStation.title,
          lat: segment.endStation.lat,
          lon: segment.endStation.lon,
          country: segment.startStation.country,
        });
      }
      return acc;
    }, new Map());

    nextState.uniqueAirports = [...uniqueAirports].map(([, item]) => item);

    if (nextState.sequence) {
      // Label each airport for sequence
      nextState.labels = nextState.uniqueAirports.map(([, item]) => ({
        lat: item.lat,
        lng: item.lon,
        text: item.id,
      }));
    } else {
      // Label only the most popular airports in non-sequence
      // TODO: Implement popular airport
    }

    nextState.stats = calculateStats({
      flights: nextState.flights,
      uniqueAirports: nextState.uniqueAirports,
      uniqueRoutes: nextState.uniqueRoutes,
    });
  }

  return nextState || state;
}
